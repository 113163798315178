import { render, staticRenderFns } from "./foodClock.vue?vue&type=template&id=5caf64ca&scoped=true&"
import script from "./foodClock.vue?vue&type=script&lang=js&"
export * from "./foodClock.vue?vue&type=script&lang=js&"
import style0 from "./foodClock.vue?vue&type=style&index=0&id=5caf64ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5caf64ca",
  null
  
)

export default component.exports