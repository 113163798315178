<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleA"
      append-to-body
      title="管理小组"
      :show-close="false"
      :close-on-click-modal="false"
      width="50%"
    >
        <el-table
        :data="groupArr"
        border
        stripe
        style="width: 100%"
        >
            <el-table-column prop="groupName" label="小组名称" />
            <el-table-column label="操作" width="300" >
                <template slot-scope="scope">
                    <el-button size="small" type="primary" @click="addGroup(scope.row.groupName,scope.row.groupID)">编辑</el-button>
                    <el-button size="small" type="success" @click="MemberClick(scope.row.groupName,scope.row.groupID)">管理成员</el-button>
                    <el-button size="small" type="danger" @click="deleteGroup(scope.row.groupID)">解散小组</el-button>
                </template>
            </el-table-column>
        </el-table>
        
        <div slot="title" class="dialog-title-box">
            <div>管理小组</div>
            <div>
                <!-- <el-button icon="el-icon-refresh" @click="getGroup()">刷新</el-button> -->
                <el-button type="primary" icon="el-icon-plus" @click="addGroup()">新增小组</el-button>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closes()">关 闭</el-button>
        </span>
    </el-dialog>
    <managingMember ref="managingMember" />
  </div>
</template>
  <script>
import { manageMyGroup,getMyGroup,DelMyGroup } from "@/api/index.js";
import managingMember from "./managingMember.vue";
export default {
  components: {
    managingMember
  },
  data() {
    return {
        dialogVisibleA: false,
      groupArr:[]
    };
  },
  created() {
    this.getGroup()
  },
  methods: {
    open(){
        this.dialogVisibleA = true
    },
    // 管理小组成员
    MemberClick(name,id){
        console.log(123)
        this.$refs.managingMember.open(name,id)
    },
    // 获取小组列表
    getGroup(){
        getMyGroup({
            ClassID: localStorage.getItem("classID") || ""
        }).then(res => {
            this.groupArr = res.data
        })
    },
    // 关闭弹窗
    closes() {
      this.dialogVisibleA = false;
    },
    // 新增、修改小组
    addGroup(name,id){
        let isD = false
        let _this = this
        this.$prompt('请输入小组名称', id?'修改小组名称':'新增小组', {
            inputValue: name || '',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputErrorMessage: '小组名称限制1-20字',
            inputValidator: (value)=>{
                if(!value){
                    return '小组名称限制1-20字'
                }
                if(value.trim().length < 1 || value.trim().length > 20){
                    return '小组名称限制1-20字'
                }
            },
            closeOnClickModal:false,
            beforeClose: (action, instance, done) => {
                if(isD) return
                if(action === 'confirm'){
                    isD = true
                    manageMyGroup({
                        classID: localStorage.getItem("classID") || "",
                        groupName: instance.inputValue,
                        groupID: id || ''
                    }).then(res =>{
                        done();
                        isD = false
                        _this.getGroup()
                        _this.$parent.getMyGroupList()
                    }).catch(err =>{
                        isD = false
                    })
                }else{
                    done();
                } 
            }
        }).then().catch();
    },
    // 解散小组
    deleteGroup(id) {
        let _this = this
        this.$confirm('是否确认解散该小组?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            DelMyGroup({
                groupID:id
            }).then(res =>{
                this.$message.success('解散小组成功')
                _this.getGroup()
                _this.$parent.getMyGroupList()
            })
        }).catch();
      }
  },
};
</script>
<style>
.el-button--small {
    font-size: 0.85rem !important;
    padding: 0.5rem 0.7rem !important;
    zoom: 0.9 !important;
}
.el-table,
.el-input{
    font-size: 18px;
}
</style>
<style scoped>
.dialog-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dialog-title-box>div:nth-child(1){
    font-size: 22px;
    color: #303133;
}
</style>