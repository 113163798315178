<!-- 午餐打卡 -->
<template>
  <div>
    <div
      class="letter_pages_box_css"
      :class="letterShow ? 'letter_pagesA' : 'letter_pagesB'"
    >
      <div class="letter_box_css">
        <div
          class="letter_css bgColor"
          @click="allClock()"
          :class="{ bgColor_hover: !this.isAll }"
        >
          {{ this.isAll ? '批量不就餐' : '批量就餐' }}
        </div>
        <!-- <div class="numberBox">
          <div class="numBerItem">就 餐:{{ isClockNumber }}人</div>
          <div>不就餐:{{ isNoClockNumber }}人</div>
        </div> -->
      </div>
      <div
        class="letter_box_css clockItem"
        v-for="(item, index) in xuList"
        :key="index"
      >
        <!-- <div class="avaterBorder" :class="{ isAvater: item.isClock == 1 }">
          <avatar :imagesrc="item.photo" fontSize="1.25" />
        </div> -->

        <div class="nameBox">
          {{ item.userName }}
        </div>

        <el-radio-group
          @input="selectedChange($event, item, index)"
          v-model="item.MealStatus"
        >
          <el-radio :label="1">就餐</el-radio>
          <el-radio :label="0">不就餐</el-radio>
        </el-radio-group>
      </div>

      <div style="height: 20px;"></div>
      <!-- 提交按钮 -->
      <div class="subMitBox" @click="submitFn">提交</div>
      <i class="iconfont xe629_Css" @click="letterShow = false">&#xe629;</i>
    </div>
  </div>
</template>

<script>
//引入就餐打卡接口
import {
  getlunchRecordBvTime,
  addlunchRecordBvTime,
  updatelunchRecordBvTime,
} from '@/api/index.js'
import moment from 'moment'
export default {
  data() {
    return {
      isAll: false, //是否批量
      letterShow: false, //是否显示
      xuList: [], //学生数组
      moment: moment,
      classID: '', //班级主键
    }
  },
  computed: {
    //就餐数量
    isClockNumber() {
      return this.xuList.filter((item) => item.isClock == 1).length
    },
    //不就餐数量
    isNoClockNumber() {
      return this.xuList.filter((item) => item.isClock == 2).length
    },
  },
  methods: {
    //打开
    openTrue(classId, list) {
      console.log('打印接受到的', classId, list)
      this.classID = classId
      //获取就餐列表接口
      this.getLunchList()
      this.letterShow = !this.letterShow
    },

    //获取班级就餐人员
    getLunchList() {
      let obj = {
        classID: this.classID,
        lunchDate: this.moment().format('YYYY-MM-DD'),
        lrType: 2, //2午餐 3晚餐
      }
      getlunchRecordBvTime(obj)
        .then((res) => {
          console.log('打印获取的就餐人员', res)
          this.xuList = res.data
          for (let i = 0; i < this.xuList.length; i++) {
            this.xuList[i].MealStatus = Number(this.xuList[i].MealStatus)
          }
          // 使用 some 方法检查是否有至少一个元素满足条件
          this.isAll = this.xuList.every((item) => item.MealStatus == 1)
        })
        .catch((err) => {
          console.log('获取人员失败', err)
        })
    },

    //批量就餐 or 取消
    allClock() {
      console.log('点击了批量就餐')
      if (this.isAll) {
        //不就餐
        this.$confirm('您确定要批量不就餐, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.isAll = false
            for (let i = 0; i < this.xuList.length; i++) {
              this.$set(this.xuList[i], 'MealStatus', 0)
              //   this.xuList[i].isClock = 2
            }

            let obj = {
              classID: this.classID,
              lunchDate: this.moment().format('YYYY-MM-DD'),
              lrType: 2,
              Eating: '', //就餐人员编号集合
              notEating: '', //不就餐人员编号集合
            }
            let notEatingList = []
            for (let i = 0; i < this.xuList.length; i++) {
              notEatingList.push(this.xuList[i].userID)
            }
            obj.notEating = notEatingList.join(',')
            console.log('打印最后提交的', obj)

            addlunchRecordBvTime(obj)
              .then((res) => {
                this.$message({
                  message: '提交就餐记录成功',
                  type: 'success',
                })
                this.getLunchList()
              })
              .catch((err) => {})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      } else {
        this.$confirm('您确定要批量就餐, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.isAll = true
            for (let i = 0; i < this.xuList.length; i++) {
              //   this.xuList[i].isClock = 1
              this.$set(this.xuList[i], 'MealStatus', 1)
            }

            let obj = {
              classID: this.classID,
              lunchDate: this.moment().format('YYYY-MM-DD'),
              lrType: 2,
              Eating: '', //就餐人员编号集合
              notEating: '', //不就餐人员编号集合
            }
            let eatingList = []
            for (let i = 0; i < this.xuList.length; i++) {
              eatingList.push(this.xuList[i].userID)
            }
            obj.Eating = eatingList.join(',')
            console.log('打印最后提交的', obj, this.xuList)

            addlunchRecordBvTime(obj)
              .then((res) => {
                this.$message({
                  message: '提交就餐记录成功',
                  type: 'success',
                })
                this.getLunchList()
              })
              .catch((err) => {})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      }
    },

    //单个选择就餐状态
    selectedChange(e, obj, index) {
      console.log('点击了单个选择', e, obj, index, typeof e)
      this.xuList[index].MealStatus = e
      if (obj.LRID && obj.LRID != 0) {
        //更改单个状态
        updatelunchRecordBvTime({
          LRID: obj.LRID,
          isEating: e,
          lrType: 2,
        }).then((res) => {
          this.$message({
            message: '更改就餐状态成功',
            type: 'success',
          })
          //获取就餐列表接口
          this.getLunchList()
        })
      }

      this.$forceUpdate()
      console.log('打印数组', this.xuList)
    },

    //提交
    submitFn() {
      console.log('点击了提交')

      let obj = {
        classID: this.classID,
        lunchDate: this.moment().format('YYYY-MM-DD'),
        lrType: 2,
        Eating: '', //就餐人员编号集合
        notEating: '', //不就餐人员编号集合
      }
      let eatingList = []
      let notEatingList = []
      for (let i = 0; i < this.xuList.length; i++) {
        if (this.xuList[i].MealStatus == 0) {
          //不就餐
          notEatingList.push(this.xuList[i].userID)
        } else if (this.xuList[i].MealStatus == 1) {
          //就餐
          eatingList.push(this.xuList[i].userID)
        }
      }
      obj.Eating = eatingList.join(',')
      obj.notEating = notEatingList.join(',')
      console.log('打印最后提交的', obj)
      addlunchRecordBvTime(obj)
        .then((res) => {
          this.$message({
            message: '提交就餐记录成功',
            type: 'success',
          })
          this.letterShow = false
        })
        .catch((err) => {})
    },
  },
}
</script>

<style scoped>
.letter_pages_box_css {
  box-sizing: border-box;
  /* height: calc(100vh - 330px); */
  overflow-y: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 148px;
  z-index: 999;
  background-color: #fff;
  transition: height 0.25s;
}
.letter_pagesA {
  padding: 10px 17px;
  height: calc(100vh - 380px);
}
.letter_pagesB {
  padding: 0px;
  height: 0px;
}
.letter_box_css {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}
.clockItem {
  align-items: center;
  height: 30px;
  border-bottom: 1px solid #d3d2d2;
}
.nameBox {
  min-width: 55px;
  width: auto;
  font-size: 16px;
}
.avaterBorder {
  border: 2px solid #d3d2d2;
  border-radius: 50%;
  box-sizing: border-box;
}
.isAvater {
  border: 2px solid #38a8fe !important;
  box-sizing: border-box;
}
.letter_box_css:nth-child(1) .letter_css {
  width: 120px;
  height: 38px;
  font-size: 20px;
}

.letter_css {
  width: 46px;
  height: 46px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}
.numberBox {
  font-size: 14px;
  color: #38a8fe;
}
.numBerItem {
  white-space: pre;
}
.chinese_box_css {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bgColor {
  background-color: #eaf5fe;
  color: #45adff;
}
.subMitBox {
  background: linear-gradient(to right, #4fcdfe, #38a8fe);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 38px;
  border-radius: 500px;
  font-size: 20px;
  flex-shrink: 0;
  margin: 0 auto;
  margin-bottom: 7px;
  cursor: pointer;
}
.isCheck {
  color: #409eff !important;
}
.bgColor_hover {
  background: linear-gradient(to right, #4fcdfe, #38a8fe) !important;
  color: #fff !important;
}

.xe629_Css {
  position: absolute;
  top: 25px;
  right: 20px;
  font-size: 16px;
  cursor: pointer;
}
.el-radio {
  margin-right: 10px !important;
}
.el-radio-group {
  display: flex;
  align-items: center;
}
</style>
