<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :title="'管理小组成员 (' + groupName + ')'"
      :show-close="false"
      :close-on-click-modal="false"
      width="50%"
      class="dialog-managingMember"
      top="5vh"
    >
    <div class="box_item_css">
      <div
        v-for="(item, index) in groupMemberArr"
        :key="index"
        @click="clickScore(item.UserID)"
      >
        <avatar :imagesrc="item.photo" fontSize="3.75" />
        <div
          class="checkMark_css"
          :class="{ checkMark_hover_css: getIncludes(item.UserID) }"
        >
          <i class="el-icon-check"></i>
        </div>
        <div style="margin-top: 5px">{{ item.userName }}</div>
      </div>
      <i v-for="item in 12" :key="'id_' + item"></i>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closes()">关 闭</el-button>
        <el-button type="primary" @click="clickSave()">确 定 ( {{studentIds.length}} )</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    <script>
import { GetClassUserList, getGroupPerson, AddClassGroupDetailed } from "@/api/index.js";
export default {
  data() {
    return {
      dialogVisible: false,
      groupMemberArr: [],
      groupID: "",
      groupName: "",
      studentIds: [],
    };
  },
  methods: {
    open(name, id) {
        this.groupName = name;
        this.groupID = id;
        this.dialogVisible = true;
        this.getGroupMember()
        GetClassUserList({
            ClassID: localStorage.getItem("classID") || "",
            rType: 1,
        }).then((res) => {
            this.groupMemberArr = res.data;
        });
    },
    // 获取小组成员列表
    getGroupMember() {
        getGroupPerson({
            GroupID: this.groupID,
        }).then((res) => {
            this.studentIds = []
            res.data.map(val =>{
                this.studentIds.push(val.UserID)
            })
        });
    },
    getIncludes(id) {
      for (let i = 0; i < this.studentIds.length; i++) {
        if (this.studentIds[i] == id) {
          return true;
        }
      }
      return false;
    },
    // 关闭弹窗
    closes() {
      this.dialogVisible = false;
      this.studentIds = []
      this.groupMemberArr = []
    },
    clickScore(UserID){
        let num = true;
        for (let i = 0; i < this.studentIds.length; i++) {
          if (this.studentIds[i] == UserID) {
            this.studentIds.splice(i, 1);
            num = false;
            break;
          }
        }
        if (num) {
          this.studentIds.push(UserID);
        }
    },
    // 确定添加
    clickSave(){
        AddClassGroupDetailed({
            groupID:this.groupID,
            userID:this.studentIds.toString()
        }).then(res =>{
            this.$message.success('添加小组成员成功')
            this.closes()
        })
    }
  },
};
</script>
  <style>
.el-button--small {
  font-size: 0.85rem !important;
  padding: 0.5rem 0.7rem !important;
  zoom: 0.9 !important;
}
.el-table,
.el-input {
  font-size: 18px;
}
</style>
<style scoped>
  .checkMark_css {
  position: absolute;
  top: 53px;
  right: -4px;
  height: 22px;
  width: 22px;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: #e2dcdc;
}

.checkMark_css>i {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.checkMark_hover_css {
  background-color: #5ba4f9 !important;
}
.dialog-managingMember /deep/ .el-dialog__body{
    padding: 0 !important;
}
.box_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  max-height: calc(100vh - 230px);
  box-sizing: border-box;
  overflow-y: auto;
}

.box_item_css>i {
  margin: 0 10px;
  width: 72px;
  height: 0px;
}

.box_item_css>div {
  margin: 0 10px 15px 10px;
  width: 72px;
  /* overflow: hidden; */
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  position: relative;
}
</style>