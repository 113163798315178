<template>
  <div>
    <div class="top_css_alert">
      <el-carousel height="60px" direction="vertical">
        <el-carousel-item
          class="top_css_alert_item"
          v-for="(item, index) in notification"
          :key="index"
        >
          <div class="top_css_alert_DIV">
            {{ item.hwTitle }}
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box_css">
      <div class="box_left_css">
        <div class="left_title_css">
          <el-radio-group
            v-model="left_top_value"
            @change="left_top_value_click"
          >
            <el-radio-button :label="1">分组</el-radio-button>
            <el-radio-button :label="2">考勤</el-radio-button>
          </el-radio-group>
        </div>

        <!-- 分组 -->
        <div v-show="left_top_value == 1">
          <div class="left_auto_css">
            <div
              class="left_item_css"
              @click="switchGroup(item.groupID)"
              :class="{ hover_css: seceteA == item.groupID }"
              v-for="(item, index) in listGroup"
              :key="index"
            >
              {{ item.groupName }}
            </div>
            <div class="left_item_css" @click="managementGroupClick()">
              <i class="el-icon-s-tools"></i>
              管理小组
            </div>
          </div>
          <div class="left_bottom_css">
            <div v-if="!scoreShow" style="width: 100%;">
              <el-button type="primary" @click="scoreShowTrue()"
                >点评多人</el-button
              >
              <div style="position: relative;">
                <!-- <div class="settingBox">
                  <img src="@/assets/ai/setting.gif" alt="" />
                </div> -->
                <!-- <el-button type="success" @click="autoFn()">
                  <div>智能点评</div>
                </el-button> -->
              </div>
            </div>
            <div v-if="scoreShow">
              <el-button type="danger" @click="scoreShowFalse()"
                >取消</el-button
              >
              <el-button type="primary" @click="SendComments(1)"
                >发送点评({{ studentIds.length }})</el-button
              >
            </div>
          </div>
        </div>

        <!-- 考勤 -->
        <div v-show="left_top_value == 2">
          <div class="left_auto_css">
            <div
              class="left_item_css"
              @click="switchSign(item)"
              :class="{ hover_css: seceteB == item.arIndex }"
              v-for="(item, index) in CheckList"
              :key="index"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            class="right_bottom_css"
            v-if="signObj.show && left_top_value == 2 && !batchValue"
          >
            <div class="display_center" style="cursor: pointer;">
              <div class="checkMark_cssA checkMark_yidao_css"></div>
              已到({{ getStatus(1) }})
            </div>
            <div class="display_center" style="cursor: pointer;">
              <div class="checkMark_cssA"></div>
              未到({{ getStatus(0) }})
            </div>
            <div class="display_center" style="cursor: pointer;">
              <div class="checkMark_cssA checkMark_qingjia_css"></div>
              请假({{ getStatus(2) }})
            </div>
          </div>
          <div
            class="right_bottom_css"
            v-if="signObj.show && left_top_value == 2 && batchValue"
          >
            <div style="margin-top:0.8rem;" @click="clickSave">
              <el-button type="primary">确定考勤</el-button>
            </div>
            <div
              class="display_center"
              style="cursor: pointer;"
              @click="selectAll('1')"
            >
              <div class="checkMark_cssA checkMark_yidao_css"></div>
              全部已到
            </div>
            <div
              class="display_center"
              style="cursor: pointer;"
              @click="selectAll('0')"
            >
              <div class="checkMark_cssA"></div>
              全部未到
            </div>
          </div>
        </div>
      </div>
      <!-- 人员 -->
      <div class="box_item_css" v-if="left_top_value == 1 && list.length > 0">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="clickScore(item)"
        >
          <avatar :imagesrc="item.photo" fontSize="4.75" />
          <div class="leave_css" v-if="item.Leave">请假</div>
          <div v-if="item.animation" class="animation_css">
            <div class="strip-shaped"></div>
            <div class="strip-shaped"></div>
            <div class="strip-shaped"></div>
            <div class="strip-shaped"></div>
            <div class="strip-shaped"></div>
            <div class="strip-shaped"></div>
            <img class="animation_css_img" src="@/assets/dianzan123.png" />
          </div>
          <div v-if="!scoreShow && seceteA == 1" class="top_box_css">
            <i class="el-icon-star-on" :class="[getColor(item.de)]"></i>
            <i class="el-icon-star-on" :class="[getColor(item.zhi)]"></i>
            <i class="el-icon-star-on" :class="[getColor(item.mei)]"></i>
            <i class="el-icon-star-on" :class="[getColor(item.lao)]"></i>
            <i class="el-icon-star-on" :class="[getColor(item.ti)]"></i>
          </div>
          <div v-if="!scoreShow && seceteA == 1" class="score_css">
            {{ item.allScore }}
          </div>
          <div v-if="seceteA != 1" class="score_css">{{ item.allScore }}</div>
          <div
            v-if="scoreShow && !item.Leave"
            class="checkMark_css"
            :class="{ checkMark_hover_css: getIncludes(item.UserID) }"
          >
            <i class="el-icon-check"></i>
          </div>
          <div class="username">
            <div class="snoBox" v-if="item.SNO">{{ item.SNO }}&ensp;</div>
            <div>{{ item.userName }}</div>
          </div>
        </div>
        <!-- 全班 -->
        <div @click="scoreOpen()" v-if="list.length > 0 && seceteA == '1'">
          <avatar :imagesrc="''" fontSize="4.75" />
          <div v-if="!scoreShow" class="score_css zeroJia_css">
            请假：{{ getLeave() }}人
          </div>
          <div v-else class="score_css">已选 {{ studentIds.length }}人</div>
          <div style="margin-top: 5px">
            {{ scoreShow ? '全部学生' : '全班' }}
          </div>
        </div>
        <i v-for="item in 12" :key="'id_' + item"></i>
      </div>
      <div
        class="box_item_css weiqian_css"
        v-if="left_top_value == 1 && list.length == 0"
      >
        该小组暂无成员
      </div>

      <!-- 签到 -->
      <div class="box_item_css" v-if="signObj.show && left_top_value == 2">
        <div @click="batchKQ()">
          <div class="img_css">
            <img src="@/assets/piliang.jpg" alt="批量考勤" />
          </div>
          <div class="name_css">{{ batchValue ? '取消' : '批量考勤' }}</div>
        </div>
        <div
          v-for="(item, index) in signList"
          :key="index"
          @click="CsignIn(item, index)"
        >
          <div class="img_css">
            <avatar :imagesrc="item.photo" fontSize="4.75" />
            <div class="qingjia_Css" v-if="item.status == 2 && !batchValue">
              请假
            </div>
          </div>
          <div class="name_css">{{ item.userName }}</div>
          <div
            class="signIn_css"
            :class="item.status == 1 ? 'signInA_css' : 'signInB_css'"
            v-if="!batchValue"
          >
            {{
              item.status == 1 ? '已签到' : item.status == 2 ? '请假' : '未签到'
            }}
          </div>
          <div
            v-if="batchValue"
            class="checkMarkA_css"
            :class="
              item.status == 1
                ? 'checkMark_yidao_css'
                : item.status == 2
                ? 'checkMark_qingjia_css'
                : ''
            "
          ></div>
        </div>
        <i v-for="item in 12" :key="'id_' + item"></i>
      </div>
      <div
        class="box_item_css weiqian_css"
        v-if="!signObj.show && left_top_value == 2"
      >
        {{ CheckList.length > 0 ? '未到签到时间' : '请刷新后重试' }}
      </div>
      <div class="box_right_css">
        <div class="phbbga_top_css">
          <img src="@/assets/phbbga.png" />
          <img src="@/assets/phbbgb.png" />
        </div>
        <div class="box_right_auto_css">
          <el-empty
            v-if="leaderboardList.length == 0"
            description="快来坐上榜单宝座吧~"
          ></el-empty>
          <div
            class="leaderboardList_css"
            v-for="(item, index) in leaderboardList"
            :key="index"
          >
            <div>
              <img v-if="index == 0" src="@/assets/phb1.png" />
              <img v-else-if="index == 1" src="@/assets/phb2.png" />
              <img v-else-if="index == 2" src="@/assets/phb3.png" />
              <span v-else>{{
                index + 1 < 10 ? '0' + (index + 1) : index + 1
              }}</span>
            </div>
            <div>
              <avatar :imagesrc="item.StudentPhoto" fontSize="2" />
              <div class="lea_name_css">{{ item.StudentName }}</div>
            </div>
            <div>
              <div>+{{ item.allScore }}分</div>
            </div>
          </div>
        </div>
        <!-- 右下角 排序 -->
        <div class="sort_box_css">
          <!-- 下拉查询 -->
          <el-dropdown placement="top">
            <div class="sort_box_itemA">
              <div class="xe611_css">
                <i class="iconfont" v-html="rankingObj.icon"></i>
              </div>
              {{ rankingObj.text }}
              <i class="iconfont xe604_css">&#xe604;</i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in studentAList" :key="item.value">
                <div
                  style="font-size:16px;"
                  :style="{
                    color: rankingObj.value == item.value ? 'blueviolet' : '',
                  }"
                  @click="closePopup(item)"
                >
                  {{ item.name }}
                  <i
                    class="iconfont"
                    style="margin-left: 4px;"
                    v-if="rankingObj.value == item.value"
                    >&#xe615;</i
                  >
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 查找学生 -->
          <div class="sort_box_itemA" @click="letterOpen()">
            <div class="xe611_css">
              <i class="iconfont">&#xe61a;</i>
            </div>
            <span v-if="letterValue == ''">查找学生</span>
            <span v-else>{{ letterValue }}</span>
          </div>
          <!-- 午餐记录 -->
          <div class="sort_box_itemA" @click="foodOpen()">
            <div class="xe611_css">
              <i class="iconfont">&#xe634;</i>
            </div>
            <span v-if="letterValue == ''">午餐记录</span>
            <span v-else>{{ letterValue }}</span>
          </div>
          <!-- 按时间查询得分 -->
          <!-- <div class="sort_box_itemA" @click="timeOpen()">
            <div class="xe611_css">
              <i class="iconfont">&#xe670;</i>
            </div>
            <span class="onehidden" v-if="timeArr[0] && timeArr[1]"
              >{{ timeArr[0] }}至{{ timeArr[1] }}</span
            >
            <span v-else>按时间查询得分</span>
            <div
              v-if="timeArr[0] && timeArr[1]"
              class="xe611_css deleteTime"
              @click.stop="deleteTime()"
            >
              <i class="iconfont">&#xe629;</i>
            </div>
          </div> -->
          <el-date-picker
            v-model="timeArr"
            type="daterange"
            class="pickerTime"
            ref="datePicker"
            value-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getSubitemRanking"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <letter ref="letter" @letterC="letterClick" class="letter_css" />
        <foodClock ref="foodClockRef" class="letter_css"></foodClock>
      </div>
    </div>
    <div style="height: 8rem"></div>
    <scorePopup ref="scorePopup" />
    <managementGroup ref="managementGroup" />
  </div>
</template>
<script>
import {
  GetClassUserList,
  getClassLeaveUser,
  getMyGroup,
  getGroupPerson,
  getAttendanceRecord,
  attendanceRecordSave,
  getAttendanceTime,
  getClassTask,
  attendanceRecordSave2,
  StudentReportTotalRanking,
  StudentReportSubitemRanking2,
} from '@/api/index.js'
let timer = null
let ServerTime = null
import scorePopup from './scorePopup.vue'
import letter from './letter.vue'

//引入食堂打卡组件
import foodClock from './foodClock.vue'
import managementGroup from './managementGroup.vue'

import moment from 'moment'
import api from '@/api/api'
export default {
  components: {
    scorePopup,
    letter,
    managementGroup,
    foodClock,
  },
  data() {
    return {
      timeArr: ['', ''],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      rankingObj: {
        name: '按姓名首字母排序',
        value: 0,
        icon: '&#xe611;',
        text: '按首字母',
      },
      ranking: 0,
      studentAList: [
        {
          name: '按姓名首字母排序',
          value: 0,
          icon: '&#xe611;',
          text: '按首字母',
        },
        { name: '按得分降序', value: 1, icon: '&#xe64a;', text: '按得分降序' },
        { name: '按得分升序', value: 2, icon: '&#xe64d;', text: '按得分升序' },
      ],
      letterValue: '',
      left_top_value: 1, // 分组，考勤
      seceteA: '1', // 那个小组
      seceteB: '0', // 哪次考勤
      leaderboardList: [], // 排行榜
      notification: [], // 通知
      moment: moment,
      className: localStorage.getItem('className'),
      classID: localStorage.getItem('classID'),
      list: [], // 全部学生
      allList: [], // 暂存学生
      LeaveList: [], // 请假人员
      listGroup: [], // 小组
      signObj: {
        startTime: '',
        endTime: '',
        show: false,
      },
      batchValue: false,

      studentIds: [], // 多人选择人员
      scoreShow: false, // 是否多人点评

      CheckList: [], // 考勤列表
      signList: [], // 考勤人员列表
    }
  },
  async created() {
    ServerTime = await api.getServerTime()
    await this.ServerTime()
    this.$bus.$emit('GetEvaluation')
    this.getClassList() // 班级学生成员
    this.getMyGroupList() // 小组列表
    this.getStudentReportTotalRanking()
    // 考勤列表
    getAttendanceTime({
      classID: this.classID,
    }).then((res) => {
      this.CheckList = res.data
      let item = this.CheckList[0]
      for (let i = 0; i < this.CheckList.length; i++) {
        let show = this.getIcon(
          this.CheckList[i].startTime,
          this.CheckList[i].endTime,
          2
        )
        if (show) {
          item = this.CheckList[i]
          break
        }
      }

      this.seceteB = item.arIndex
      this.signObj = {
        startTime: item.startTime,
        endTime: item.endTime,
        show: !this.getIcon(item.startTime, '', 1),
      }
      if (this.signObj.show) {
        let ttm = localStorage.getItem('newTime') || ''
        getAttendanceRecord({
          classID: this.classID,
          // rDate: moment(ttm).format("YYYY-MM-DD"),
          arIndex: item.arIndex,
        }).then((res) => {
          this.signList = res.data
          this.signList = this.signList.sort(function(a, b) {
            return Number(b.status) - Number(a.status)
          })
        })
      } else {
        this.signList = []
      }
    })

    // 定时获取公告
    this.getNotification()
    timer = setInterval(() => {
      this.getNotification()
    }, 1000 * 60 * 3)
  },
  beforeDestroy() {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  },
  mounted() {
    this.$bus.$off('indexShua')
    this.$bus.$on('indexShua', () => {
      this.seceteA = '1'
      this.list = JSON.parse(JSON.stringify(this.allList))

      this.getShuJu()
    })
    setTimeout(() => {
      console.log('数组', this.list)
    }, 5000)
  },
  methods: {
    //跳转到智能点评
    autoFn() {
      let routeData = this.$router.resolve({
        path: '/aiMake',
      })
      // 打开新页面
      window.open(routeData.href, '_blank')
    },
    async ServerTime() {
      let time = moment(ServerTime || undefined).valueOf()
      let ttm = localStorage.getItem('newTime')
      if (ttm) {
        if (time - moment().valueOf() < 1000 * 60 * 5) {
          return true
        } else if (moment().valueOf() - time > -1000 * 60 * 5) {
          return true
        } else {
          await new Promise((res) => setTimeout(res, 500))
          return this.ServerTime()
        }
      } else {
        await new Promise((res) => setTimeout(res, 500))
        return this.ServerTime()
      }
    },
    getScore(val) {
      return (
        (val.de || 0) +
        (val.zhi || 0) +
        (val.ti || 0) +
        (val.mei || 0) +
        (val.lao || 0)
      )
    },
    // 获取星星颜色
    getColor(val) {
      // console.log('星星', val)
      if (val > 80) {
        return 'blue_color'
      } else if (val > 60) {
        return 'green_color'
      } else if (val > 40) {
        return 'yellow_color'
      } else if (val > 20) {
        return 'orange_color'
      } else if (val > 0) {
        return 'red_color'
      } else {
        return 'none_color'
      }
    },
    timeOpen() {
      this.$refs.datePicker.focus()
    },
    scoreOpen() {
      if (this.scoreShow) {
        if (this.studentIds.length >= this.list.length - this.getLeave()) {
          this.studentIds = []
        } else {
          this.studentIds = []
          this.list.map((val) => {
            if (!val.Leave) {
              this.studentIds.push({
                userid: val.UserID,
                photo: val.photo || '',
                userName: val.userName,
              })
            }
          })
        }
      }
    },
    getStudentReportTotalRanking() {
      let ttm = localStorage.getItem('newTime') || ''
      StudentReportTotalRanking({
        ClassID: this.classID,
        endDate: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        startDate: moment().format('YYYY-MM-DD'),
      }).then((res) => {
        this.leaderboardList = res.data.students
      })
    },
    // 分组和考勤的切换
    left_top_value_click() {
      console.log(
        this.left_top_value,
        '分组和考勤的切换',
        this.LeaveList,
        this.list
      )
      if (this.left_top_value == 1) {
        //获取请假人员数组
        this.getClassLeaveUserList()
      }
    },
    // 统计签到人数
    getStatus(e) {
      let listM = this.signList.filter((v) => v.status == e)
      return listM.length
    },
    // 多人点评
    scoreShowTrue(groupID) {
      // 在分组选项才能点评
      if (this.left_top_value == 1) {
        this.groupID = groupID
        this.scoreShow = true
      } else {
        this.scoreShow = false
        this.groupID = '1'
      }
    },
    // 获取公告
    getNotification() {
      getClassTask({
        ClassID: this.classID,
        startindex: 1,
        endindex: 200,
        hwType: 5,
      }).then((res) => {
        this.notification = res.data
      })
    },
    // 获取人员列表
    getClassList() {
      if (this.classID && this.classID != '0') {
        GetClassUserList({
          ClassID: this.classID,
          rType: 1,
        }).then((res) => {
          this.list = res.data
          this.allList = JSON.parse(JSON.stringify(res.data))
          this.getClassLeaveUserList()
        })
      }
    },
    // 获取请假人员列表
    getClassLeaveUserList() {
      if (this.classID && this.classID != '0') {
        getClassLeaveUser({
          ClassID: this.classID,
        }).then((res) => {
          this.LeaveList = res.data
          this.getShuJu()
        })
      }
    },
    // 处理列表数据
    getShuJu() {
      console.log('处理列表中的数据', this.list, this.LeaveList)
      // let getLeaveList = localStorage.getItem("leaveList");
      // let leaveList = [];
      // if (getLeaveList && JSON.parse(getLeaveList).list) {
      //   leaveList = JSON.parse(JSON.parse(getLeaveList).list);
      // }
      // leaveList.map((val) => {
      //   this.LeaveList.push({
      //     UserID: val.studentID,
      //   });
      // });
      for (let j = 0; j < this.list.length; j++) {
        this.list[j].Leave = false
      }
      for (let i = 0; i < this.LeaveList.length; i++) {
        for (let j = 0; j < this.list.length; j++) {
          this.list[j].animation = false
          if (this.LeaveList[i].UserID == this.list[j].UserID) {
            this.list[j].Leave = true
            continue
          }
        }
      }
      // 一个排序会出现顺序问题,需要两次排序
      // this.list = this.list.sort(function (a) {
      //   return a.Leave ? 0 : -1;
      // });
      // this.list = this.list.sort(function (a) {
      //   return a.Leave ? 0 : -1;
      // });
      this.$forceUpdate()
      if (this.seceteA == 1) {
        this.getSubitemRanking()
      }
    },
    deleteTime() {
      this.timeArr = ['', '']
      this.getSubitemRanking()
    },
    getSubitemRanking() {
      // 数据统计
      let _this = this
      StudentReportSubitemRanking2({
        orgID: this.classID,
        // endDate: this.timeArr[1],
        startDate: this.timeArr[0],
      }).then((res) => {
        console.log('获取StudentReportSubitemRanking2', res)
        let students = res.data
        let xuList = JSON.parse(JSON.stringify(this.list))
        for (let i = 0; i < xuList.length; i++) {
          xuList[i].de = 0
          xuList[i].zhi = 0
          xuList[i].ti = 0
          xuList[i].mei = 0
          xuList[i].lao = 0
          xuList[i].allScore = 0
        }
        for (let i = 0; i < xuList.length; i++) {
          for (let j = students.length - 1; j >= 0; j--) {
            if (students[j].userID == xuList[i].UserID) {
              xuList[i].de = students[j].de
              xuList[i].zhi = students[j].zhi
              xuList[i].ti = students[j].ti
              xuList[i].mei = students[j].mei
              xuList[i].lao = students[j].lao
              xuList[i].allScore = students[j].score
              students.splice(j, 1)
            }
          }
        }
        this.allList = xuList
        this.list = xuList
        this.$forceUpdate()
      })
    },
    // 获取小组列表
    getMyGroupList() {
      if (this.classID && this.classID != '0') {
        getMyGroup({
          ClassID: this.classID,
        }).then((res) => {
          this.listGroup = res.data
          this.listGroup.unshift({ groupName: '全班', groupID: '1' })
        })
      }
    },
    getIncludes(id) {
      for (let i = 0; i < this.studentIds.length; i++) {
        if (this.studentIds[i].userid == id) {
          return true
        }
      }
      return false
    },
    // 全选
    selectAll(e) {
      this.signList.forEach((val) => {
        if (val.status != 2) {
          val.status = e
        }
      })
    },
    // 批量考勤
    batchKQ() {
      let _this = this
      let ttm = localStorage.getItem('newTime') || ''
      getAttendanceRecord({
        classID: this.classID,
        // rDate: moment(ttm).format("YYYY-MM-DD"),
        arIndex: this.seceteB,
      }).then((res) => {
        this.signList = res.data
        this.signList = this.signList.sort(function(a, b) {
          return Number(b.status) - Number(a.status)
        })

        if (this.batchValue) {
          this.batchValue = false
          return
        }

        // let ttiime = this.getIcon(
        //   this.signObj.startTime,
        //   this.signObj.endTime,
        //   4
        // )
        // if (!ttiime) {
        //   this.$message({
        //     message: '未在签到时间范围内',
        //     type: 'error',
        //     offset: 60,
        //     customClass: 'customClass',
        //   })
        //   return
        // }
        if (Number(this.seceteB) == 0) {
          this.batchValue = true
          // 第一节课不管
          return
        }
        let leav = localStorage.getItem('leaveList')
          ? JSON.parse(localStorage.getItem('leaveList'))
          : -1
        // 存在缓存 & 是当天 & 是前一节课
        if (
          leav != '-1' &&
          leav.day == moment().format('YYYY-MM-DD') &&
          leav.arIndex == Number(this.seceteB) - 1
        ) {
          let leaveList = JSON.parse(leav.list)
          leaveList.map((val) => {
            for (let i = 0; i < _this.signList.length; i++) {
              if (_this.signList[i].studentID == val.studentID) {
                _this.signList[i].status = 2
                break
              }
            }
          })
          for (let i = 0; i < _this.signList.length; i++) {
            if (_this.signList[i].status != 2) {
              _this.signList[i].status = 1
            }
          }
          _this.signList = _this.signList.sort(function(a, b) {
            return Number(b.status) - Number(a.status)
          })

          _this.batchValue = true
        } else {
          // 查询接口
          getAttendanceRecord({
            classID: _this.classID,
            arIndex: Number(_this.seceteB) - 1,
          }).then((resA) => {
            let leaveList = resA.data
            leaveList.map((val) => {
              if (val.status == 2) {
                for (let i = 0; i < _this.signList.length; i++) {
                  if (_this.signList[i].studentID == val.studentID) {
                    _this.signList[i].status = 2
                    break
                  }
                }
              }
            })
            for (let i = 0; i < _this.signList.length; i++) {
              if (_this.signList[i].status != 2) {
                _this.signList[i].status = 1
              }
            }
            _this.signList = _this.signList.sort(function(a, b) {
              return Number(b.status) - Number(a.status)
            })

            _this.batchValue = true
          })
        }
        // let leaveList = localStorage.getItem('leaveList') ? JSON.parse(localStorage.getItem('leaveList')) : []
        // console.log(this.signList,leaveList)
        // leaveList.map(val =>{
        //   for(let i=0;i<this.signList.length;i++){
        //     if(this.signList[i].studentID == val.studentID){
        //       this.signList[i].status = 2
        //       break
        //     }
        //   }
        // })
        // for(let i=0;i<this.signList.length;i++){
        //   if(this.signList[i].status != 2){
        //     this.signList[i].status = 1
        //   }
        // }
        // this.signList = this.signList.sort(function(a, b){return Number(b.status) - Number(a.status)})

        // this.batchValue = true;
      })
    },
    // 确定考勤
    clickSave() {
      // let ttiime = this.getIcon(this.signObj.startTime, this.signObj.endTime, 4)
      // if (!ttiime) {
      //   this.$message({
      //     message: '未在签到时间范围内',
      //     type: 'error',
      //     offset: 60,
      //     customClass: 'customClass',
      //   })
      //   return
      // }
      attendanceRecordSave2({
        models: this.signList,
      }).then((res) => {
        console.log('res1111', res)
        //2024-3-13 新增
        if (res && res != 'undefined') {
          this.$message({
            message: '保存考勤成功',
            type: 'success',
            offset: 60,
            customClass: 'customClass',
          })
          let arrList = []
          this.signList.map((val) => {
            if (val.status == 2) {
              arrList.push(val)
            }
          })
          localStorage.setItem(
            'leaveList',
            JSON.stringify({
              arIndex: this.seceteB,
              day: moment().format('YYYY-MM-DD'),
              list: JSON.stringify(arrList),
            })
          )
        }

        this.getShuJu()
        let ttm = localStorage.getItem('newTime') || ''
        getAttendanceRecord({
          classID: this.classID,
          // rDate: moment(ttm).format("YYYY-MM-DD"),
          arIndex: this.seceteB,
        }).then((res) => {
          this.signList = res.data
          this.signList = this.signList.sort(function(a, b) {
            return Number(b.status) - Number(a.status)
          })
          this.getClassLeaveUserList()
        })
        this.batchValue = false
      })
    },
    // 签到
    CsignIn(item, index) {
      console.log('点击了签到', item, index)

      if (this.batchValue) {
        if (item.status == 0) {
          this.signList[index].status = 1
        } else if (item.status == 1) {
          this.signList[index].status = 2
        } else if (item.status == 2) {
          this.signList[index].status = 0
        }
        return
      }
      // let ttiime = this.getIcon(this.signObj.startTime, this.signObj.endTime, 4)
      // console.log(ttiime)
      // if (!ttiime) {
      //   this.$message({
      //     message: '未在签到时间范围内',
      //     type: 'error',
      //     offset: 60,
      //     customClass: 'customClass',
      //   })
      //   return
      // }
      if (item.status == 1) {
        this.$message({
          message: '请勿重复签到',
          type: 'error',
          offset: 60,
          customClass: 'customClass',
        })
        return
      }
      if (item.status == 2) {
        this.$message({
          message: '请假人员不可签到',
          type: 'error',
          offset: 60,
          customClass: 'customClass',
        })
        return
      }
      let ttm = localStorage.getItem('newTime') || ''
      attendanceRecordSave({
        ARID: item.ARID,
        AID: item.AID,
        studentID: item.studentID,
        // createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: 1,
      }).then((res) => {
        getAttendanceRecord({
          classID: this.classID,
          // rDate: moment(ttm).format("YYYY-MM-DD"),
          arIndex: this.seceteB,
        }).then((resA) => {
          this.signList = resA.data
          this.signList = this.signList.sort(function(a, b) {
            return Number(b.status) - Number(a.status)
          })
        })
      })
    },
    // 管理小组
    managementGroupClick() {
      this.$refs.managementGroup.open()
    },
    // 切换小组
    switchGroup(value) {
      if (this.seceteA !== value) {
        this.seceteA = value
        if (value == '1') {
          this.list = JSON.parse(JSON.stringify(this.allList))
          this.getShuJu()
        } else {
          getGroupPerson({
            GroupID: value,
          }).then((res) => {
            this.list = res.data
            this.getShuJu()
          })
        }
      }
    },
    // 获取每节课签到记录
    switchSign(item) {
      this.batchValue = false
      this.scoreShow = false
      if (this.seceteB !== item.arIndex) {
        this.signList = []
        this.seceteB = item.arIndex
        this.signObj = {
          startTime: item.startTime,
          endTime: item.endTime,
          show: !this.getIcon(item.startTime, '', 1),
        }
        if (this.signObj.show) {
          let ttm = localStorage.getItem('newTime') || ''
          getAttendanceRecord({
            classID: this.classID,
            // rDate: moment(ttm).format("YYYY-MM-DD"),
            arIndex: item.arIndex,
          }).then((res) => {
            this.signList = res.data
            this.signList = this.signList.sort(function(a, b) {
              return Number(b.status) - Number(a.status)
            })
          })
        }
      }
    },
    getIcon(StartTime, EndTime, type) {
      let ttm = localStorage.getItem('newTime') || ''
      if (type == 1) {
        return moment().isBefore(
          moment().format('YYYY-MM-DD') + ' ' + StartTime
        )
      }
      if (type == 2) {
        return moment().isBetween(
          moment().format('YYYY-MM-DD') + ' ' + StartTime,
          moment().format('YYYY-MM-DD') + ' ' + EndTime,
          'time'
        )
      }
      if (type == 3) {
        return moment(moment().format('YYYY-MM-DD') + ' ' + EndTime).isBefore()
      }
      if (type == 4) {
        let YMD = moment().format('YYYY-MM-DD') // 当天几号
        let startTime = YMD + ' ' + StartTime // 上课时间
        let endTime = YMD + ' ' + EndTime // 下课时间
        let before10 = moment(startTime)
          .subtract(10, 'm')
          .format('YYYY-MM-DD HH:mm:ss') // 课前十分钟
        let after10 = moment(endTime)
          .add(10, 'm')
          .format('YYYY-MM-DD HH:mm:ss') // 课后十分钟
        let abTimeA = moment().isBetween(before10, after10, 'time')
        // let abTimeB = moment().isBetween(endTime, after10, 'time');
        // console.log(before10, startTime,'--e',endTime, after10)
        if (abTimeA) {
          // 上课开始前10分钟和课后10分钟
          return true
        } else {
          return false
        }
      }
    },
    // 关闭点评
    scoreShowFalse(e) {
      this.scoreShow = false
      this.studentIds = []
      if (e) {
        this.getStudentReportTotalRanking()
        // 有e表示是点评成功
        let _this = this
        // 先将所以动画关闭
        for (let j = 0; j < _this.list.length; j++) {
          _this.list[j].animation = false
        }
        this.$forceUpdate()
        if (this.seceteA == 1) {
          this.getSubitemRanking()
          this.getAnimation(e)
        } else {
          getGroupPerson({
            GroupID: this.seceteA,
          }).then((res) => {
            this.list = res.data
            this.getShuJu()
            this.getAnimation(e)
          })
        }
      }
    },
    getAnimation(e) {
      let _this = this
      this.$nextTick(() => {
        for (let i = 0; i < e.length; i++) {
          for (let j = 0; j < _this.list.length; j++) {
            if (e[i].userid == _this.list[j].UserID) {
              _this.list[j].animation = true
              continue
            }
          }
        }
        this.$forceUpdate()
      })
      setTimeout(() => {
        for (let j = 0; j < _this.list.length; j++) {
          _this.list[j].animation = false
        }
      }, 3000)
    },
    // 选择人员
    clickScore(item) {
      console.log('点击了选择人员', item)
      // if (item.Leave) {
      //   this.$message.error("不可点评请假人员");
      //   return;
      // }
      if (this.scoreShow) {
        let num = true
        for (let i = 0; i < this.studentIds.length; i++) {
          if (this.studentIds[i].userid == item.UserID) {
            this.studentIds.splice(i, 1)
            num = false
            break
          }
        }
        if (num) {
          this.studentIds.push({
            userid: item.UserID,
            photo: item.photo || '',
            userName: item.userName,
          })
        }
      } else {
        this.$bus.$emit('scorePopupTrue', {
          ids: [
            {
              userid: item.UserID,
              photo: item.photo || '',
              userName: item.userName,
            },
          ],
          groupID: this.seceteA,
        })
      }
    },
    // 发送点评
    SendComments(e) {
      if (this.studentIds.length == 0) {
        this.$message({
          message: '请选择学生进行点评',
          type: 'error',
        })
        return
      }
      this.$bus.$emit('scorePopupTrue', {
        ids: this.studentIds,
        groupID: this.seceteA,
      })
    },
    letterOpen() {
      this.$refs.letter.openTrue(this.allList)
    },
    letterClick(e) {
      if (e.length == 0) {
        this.letterValue = ''
      } else {
        let proWords = e.toString()
        if (e.length > 5) {
          let svv = proWords.substring(0, 9)
          this.letterValue = svv.replace(/[,.\s]+/g, '、') + '...'
        } else {
          this.letterValue = proWords.replace(/[,.\s]+/g, '、')
        }
      }
      this.letterData(e)
      // this.$refs.student.letterData(e)
    },
    //打开食堂打卡
    foodOpen() {
      console.log('打开食堂打卡')
      this.$refs.foodClockRef.openTrue(this.classID, this.allList)
    },
    getLeave() {
      let num = 0
      for (let j = 0; j < this.list.length; j++) {
        if (this.list[j].Leave) {
          num += 1
        }
      }
      return num
    },
    closePopup(e) {
      this.rankingObj = e
      this.getdetailData(e.value)
    },
    // 修改排序规则
    getdetailData(e) {
      if (e || e == 0) {
        this.ranking = e
      }
      // 0按姓名首字母
      // 1按得分降序
      // 2按得分升序
      let arr = JSON.parse(JSON.stringify(this.list))
      if (this.ranking == 0) {
        this.list = arr.sort((a, b) => a.userName.localeCompare(b.userName))
      } else if (this.ranking == 1) {
        this.list = arr.sort(function(a, b) {
          return b.allScore - a.allScore
        })
      } else if (this.ranking == 2) {
        this.list = arr.sort(function(a, b) {
          return a.allScore - b.allScore
        })
      }
    },
    // 首字母索引
    letterData(e) {
      console.log(e)
      if (e.length == 0) {
        this.list = JSON.parse(JSON.stringify(this.allList))
      } else {
        let arr = JSON.parse(JSON.stringify(this.allList))
        let list = []
        e.map((value) => {
          arr.map((val, index) => {
            if (value == val.userName[0]) {
              list.push(val)
            }
          })
        })
        console.log(list)
        this.list = list
        this.$forceUpdate()
      }
      console.log(this.list)
      this.getdetailData()
    },
  },
}
</script>

<style scoped>
.pickerTime {
  opacity: 0;
  position: absolute;
  right: 134px;
  top: 117px;
  width: 0 !important;
  height: 0;
}
/deep/ .el-button {
  font-size: 1rem !important;
  padding: 0.7rem 0.9rem;
  zoom: 0.9 !important;
}

.box_css {
  background-color: #ffffffda;
  border-radius: 25px;
  overflow: hidden;
  height: calc(100vh - 230px);
  margin: 0 auto;
  display: flex;
  max-width: 1710px;
}

.box_left_css {
  width: 270px;
  flex-shrink: 0;
  background-color: #ffffff8c;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
}

.box_right_css {
  width: 270px;
  flex-shrink: 0;
  background-color: #ffffff8c;
  position: relative;
}

.left_title_css {
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 26px;
  font-weight: bold;
  height: 65px;
}

/deep/ .left_title_css .el-radio-button__inner {
  font-size: 22px !important;
  padding: 12px 34px !important;
}

.left_auto_css {
  overflow-y: auto;
  height: calc(100vh - 415px);
}

.left_bottom_css {
  height: 95px;
  border-top: 1px solid #d2c9f8a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort_box_css {
  height: 145px;
  border-top: 1px solid #d2c9f8a6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  position: relative;
  z-index: 9999;
}

.sort_box_itemA {
  display: flex;
  align-items: center;
  height: 38px;
  margin-top: 7px;
  padding: 0 12px 0 4px;
  margin-left: 10px;
  border: #d3d2d2 1px solid;
  border-radius: 100px;
  min-width: 108px;
  font-size: 18px;
  color: #373a3a;
  cursor: pointer;
}
.deleteTime {
  margin-right: -8px !important;
}
.deleteTime > i {
  font-size: 10px !important;
}
.xe604_css {
  color: #6a79ff;
  font-size: 12px;
  margin-left: 7px;
}

.xe611_css {
  background-color: #d7eeff;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  flex-shrink: 0;
}

.xe611_css > i {
  color: #6a79ff;
  font-size: 14px;
}

.left_item_css {
  text-align: center;
  height: 50px;
  width: 100%;
  font-size: 22px;
  color: #626cfcdc;
  margin-top: 10px;
  line-height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 10px;
  cursor: pointer;
}

.hover_css {
  background-color: #6770f5bb !important;
  color: #fff !important;
}

.box_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 30px 15px 15px !important;
  height: calc(100vh - 230px);
  box-sizing: border-box;
  overflow-y: auto;
}

.box_item_css > i {
  margin: 0 10px;
  width: 95px;
  height: 0px;
}

.box_item_css > div {
  margin: 0 10px 15px 10px;
  width: 95px;
  /* overflow: hidden; */
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  position: relative;
}

.score_css {
  position: absolute;
  top: 75px;
  width: 80px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1000px;
  background-color: #edf8fe;
  color: #69a1f1;
  font-size: 16px;
  border: #c3d2f1 1px solid;
}

.zeroJia_css {
  color: #ec5b5b !important;
  border: #f1c3c3 1px solid !important;
  width: 92px;
}

.checkMark_css {
  position: absolute;
  top: 68px;
  right: -4px;
  height: 30px;
  width: 30px;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: #e2dcdc;
}

.checkMark_css > i {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}

.checkMark_hover_css {
  background-color: #5ba4f9 !important;
}

.top_css_alert {
  height: 60px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.top_css_alert_item {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-carousel__indicators {
  display: none;
}

.top_css_alert_DIV {
  background-color: #f0f9eb;
  color: #67c23a;
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 20px;
}

.img_css {
  width: 95px;
  height: 95px;
  border-radius: 1000px;
  overflow: hidden;
  margin-bottom: 2px;
  position: relative;
}

.img_css > img {
  width: 95px;
  height: 95px;
  border-radius: 1000px;
}

.qingjia_Css {
  position: absolute;
  top: 0;
  width: 95px;
  height: 95px;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.6);
  color: #e25656;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
}

.signInA_css {
  color: #7456e2;
}

.signInB_css {
  color: #e25656;
}

.heightCc {
  height: calc(100% - 55px) !important;
}

.weiqian_css {
  width: 100%;
  font-size: 80px;
  color: #67c23a;
  display: flex;
  align-content: center !important;
  justify-content: center;
  padding-bottom: 100px !important;
}

.leave_css {
  position: absolute;
  top: 0;
  left: 0;
  width: 95px;
  height: 95px;
  z-index: 3;
  border-radius: 1000px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 0, 0, 0.8);
  /* font-weight: bold; */
  font-size: 30px;
}

.checkMark_yidao_css {
  background-color: #5ba4f9 !important;
}

.checkMark_qingjia_css {
  background-color: #f4f40e !important;
}

.checkMark_cssA {
  height: 20px;
  width: 20px;
  border-radius: 1000px;
  background-color: #e64e63;
  margin-right: 10px;
  flex-shrink: 0;
}

.display_center {
  display: flex;
  align-items: center;
  margin: 5px;
  font-size: 16px;
}

.right_bottom_css {
  height: 95px;
  border-top: 1px solid #d2c9f8a6;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 2px;
}

.checkMarkA_css {
  position: absolute;
  top: 68px;
  right: -4px;
  height: 26px;
  width: 26px;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: #e64e63;
}

.phbbga_top_css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px 2px;
  box-sizing: border-box;
}

.phbbga_top_css > img:nth-child(1) {
  width: 70%;
  flex-shrink: 0;
  object-fit: contain;
}

.phbbga_top_css > img:nth-child(2) {
  width: 20%;
  flex-shrink: 0;
  object-fit: contain;
}

.leaderboardList_css {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.leaderboardList_css > div:nth-child(1) {
  width: 35px;
  margin-right: 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #b5b8b9;
}

.leaderboardList_css > div:nth-child(1) > img {
  width: 35px;
  object-fit: contain;
}

.leaderboardList_css > div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 18px;
  color: #373a3a;
}

.leaderboardList_css > div:nth-child(3) {
  flex-shrink: 0;
  color: #d3991c;
  font-weight: bold;
  font-size: 18px;
}

.lea_name_css {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  margin-left: 3px;
}

.box_right_auto_css {
  padding: 10px;
  box-sizing: border-box;
  height: calc(100vh - 458px);
  overflow-y: auto;
}

.animation_css {
  position: absolute;
  top: 0;
  left: 0;
  width: 95px;
  height: 95px;
}

.animation_css_img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: mymove 2s;
}

@keyframes mymove {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    top: 10px;
  }

  50%,
  75% {
    top: 28px;
    opacity: 1;
  }

  100% {
    top: 28px;
    opacity: 0;
  }
}

@keyframes mymove1 {
  0% {
    opacity: 0;
    transform: rotate(-100deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    left: -15px;
    top: 50px;
    transform: rotate(-100deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    left: -15px;
    top: 50px;
    transform: rotate(-100deg);
    height: 0px;
  }
}

@keyframes mymove2 {
  0% {
    opacity: 0;
    transform: rotate(-60deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    left: -10px;
    top: 2px;
    transform: rotate(-60deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    left: -10px;
    top: 2px;
    height: 0px;
    transform: rotate(-60deg);
  }
}

@keyframes mymove3 {
  0% {
    opacity: 0;
    transform: rotate(-30deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    left: 20px;
    top: -24px;
    transform: rotate(-30deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    left: 20px;
    top: -24px;
    transform: rotate(-30deg);
    height: 0px;
  }
}

@keyframes mymove4 {
  0% {
    opacity: 0;
    transform: rotate(30deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    right: 20px;
    top: -24px;
    transform: rotate(30deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    right: 20px;
    top: -24px;
    transform: rotate(30deg);
    height: 0px;
  }
}

@keyframes mymove5 {
  0% {
    opacity: 0;
    transform: rotate(60deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    right: -10px;
    top: 2px;
    transform: rotate(60deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    right: -10px;
    top: 2px;
    transform: rotate(60deg);
    height: 0px;
  }
}

@keyframes mymove6 {
  0% {
    opacity: 0;
    transform: rotate(100deg);
  }

  30%,
  50%,
  95% {
    opacity: 1;
    right: -15px;
    top: 50px;
    transform: rotate(100deg);
    height: 28px;
  }

  100% {
    opacity: 0;
    right: -15px;
    top: 50px;
    transform: rotate(100deg);
    height: 0px;
  }
}

.strip-shaped {
  width: 12px;
  height: 28px;
  border-radius: 100px;
  position: absolute;
  z-index: 99;
  opacity: 0;
}

.animation_css > div:nth-child(1) {
  background-color: #f07573;
  animation: mymove1 2s 0s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.animation_css > div:nth-child(2) {
  background-color: #95f073;
  animation: mymove2 2s 0.06s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.animation_css > div:nth-child(3) {
  background-color: #73f0bd;
  animation: mymove3 2s 0.12s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.animation_css > div:nth-child(4) {
  background-color: #73c1f0;
  animation: mymove4 2s 0.18s;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.animation_css > div:nth-child(5) {
  background-color: #8b73f0;
  animation: mymove5 2s 0.24s;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.animation_css > div:nth-child(6) {
  background-color: #e773f0;
  animation: mymove6 2s 0.3s;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.red_color {
  color: #f13434 !important;
}

.orange_color {
  color: #ee7a01 !important;
}

.yellow_color {
  color: #ffff2c !important;
}

.green_color {
  color: #4db232 !important;
}

.blue_color {
  color: #28b8db !important;
}

.none_color {
  color: rgb(231, 209, 209) !important;
}

.top_box_css {
  position: absolute;
  top: -18.2px;
  left: 0;
  right: 0;
  z-index: 30;
}

.top_box_css > i {
  position: absolute;
  font-size: 30px;
  color: #c7dffd;
}

.top_box_css > i:nth-child(1) {
  left: -13px;
  top: 30px;
  transform: rotate(10deg);
}

.top_box_css > i:nth-child(2) {
  left: 6px;
  top: 9px;
  transform: rotate(38deg);
}

.top_box_css > i:nth-child(3) {
  right: 6px;
  top: 9px;
  transform: rotate(-38deg);
}

.top_box_css > i:nth-child(4) {
  right: -13px;
  top: 30px;
  transform: rotate(-10deg);
}

.top_box_css > i:nth-child(5) {
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}
.onehidden {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.username {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .snoBox {
  font-size: 16px;

  white-space: pre;
} */
.snoBox {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #d7eeff;
  border: 1px solid #69a1f1;
  box-sizing: border-box;
  line-height: 25px;
  text-align: center;
  color: #69a1f1;
  font-size: 0.7rem;
  margin-right: 5px;
}
.settingBox {
  position: absolute;
  top: -15px;
  left: -15px;
}
.settingBox img {
  width: 30px;
  height: 30px;
}
</style>
