<template>
  <div>
    <div
      class="letter_pages_box_css"
      :class="letterShow ? 'letter_pagesA' : 'letter_pagesB'"
    >
      <div class="letter_box_css">
        <div
          class="letter_css bgColor"
          @click="selectedGender(-1)"
          :class="{ bgColor_hover: getAllHover() }"
        >
          全部
        </div>
      </div>
      <div
        class="letter_box_css"
        v-for="(item, index) in letterList"
        :key="index"
      >
        <div
          class="letter_css bgColor"
          @click="selectedGender(index)"
          :class="{ bgColor_hover: getHover(index) }"
        >
          {{ item.letter }}
        </div>
        <div class="chinese_box_css">
          <div
            class="letter_css"
            v-for="(cb, idx) in item.chinese"
            :key="cb.text"
            @click="selectedText(index, idx)"
            :class="{ bgColor_hover: cb.show }"
          >
            {{ cb.text }}
          </div>
        </div>
      </div>
      <div style="height: 20px;"></div>
      <i class="iconfont xe629_Css" @click="letterShow = false">&#xe629;</i>
    </div>
  </div>
</template>

<script>
import { pinyin } from 'pinyin-pro'
export default {
  data() {
    return {
      letterShow: false,
      xuList: [],
      letterList: [],
      hoverList: [],
    }
  },
  methods: {
    openTrue(e) {
      if (this.xuList.length == 0) {
        this.xuList = e
        this.getPinYin()
      }
      this.letterShow = !this.letterShow
    },
    getPinYin() {
      let PinArr = []
      // 获取姓名第一位
      this.xuList.map((val) => {
        PinArr.push(val.userName[0])
      })
      // 去重
      let newPinList = Array.from(new Set(PinArr))
      let arrList = []
      newPinList.map((val) => {
        // 拿到拼音首字母
        let pinyinLL = pinyin(val, {
          pattern: 'first',
          toneType: 'none',
          mode: 'surname',
        }).toUpperCase()
        let show = true
        for (let i = 0; i < arrList.length; i++) {
          // 找到了,加入
          if (arrList[i].letter == pinyinLL) {
            arrList[i].chinese.push({
              text: val,
              show: false,
            })
            show = false
          }
        }
        // 如果没找到,push整个对象
        if (show) {
          arrList.push({
            letter: pinyinLL,
            chinese: [
              {
                text: val,
                show: false,
              },
            ],
          })
        }
      })
      this.letterList = arrList.sort((x, y) => x.letter.localeCompare(y.letter))
    },
    selectedGender(e) {
      if (e == -1) {
        this.hoverList = []
        this.letterList.forEach((val) => {
          val.chinese.forEach((value) => {
            if (value.show) {
              value.show = false
            }
          })
        })
      } else {
        let chinese = this.letterList[e].chinese
        let nums = 0
        for (let i = 0; i < chinese.length; i++) {
          if (chinese[i].show) {
            nums += 1
          }
        }
        if (chinese.length == nums) {
          for (let i = 0; i < chinese.length; i++) {
            this.letterList[e].chinese[i].show = false
          }
        } else {
          for (let i = 0; i < chinese.length; i++) {
            this.letterList[e].chinese[i].show = true
          }
        }
        this.hoverList = []
        this.letterList.map((val) => {
          val.chinese.map((value) => {
            if (value.show) {
              this.hoverList.push(value.text)
            }
          })
        })
      }
      this.getValue()
    },
    getAllHover() {
      return this.hoverList.length == 0
    },
    // 单个选择
    selectedText(A, B) {
      this.letterList[A].chinese[B].show = !this.letterList[A].chinese[B].show
      let ttb = this.letterList[A].chinese[B]
      let num = this.hoverList.indexOf(ttb.text)
      if (ttb.show) {
        if (num < 0) {
          this.hoverList.push(ttb.text)
        }
      } else {
        if (num > -1) {
          this.hoverList.splice(num, 1)
        }
      }
      this.getValue()
    },
    getHover(A) {
      let chinese = this.letterList[A].chinese
      let nums = 0
      for (let i = 0; i < chinese.length; i++) {
        if (chinese[i].show) {
          nums += 1
        }
      }
      return chinese.length == nums
    },
    getValue() {
      this.$emit('letterC', this.hoverList)
    },
  },
}
</script>

<style scoped>
.letter_pages_box_css {
  box-sizing: border-box;
  /* height: calc(100vh - 330px); */
  overflow-y: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 148px;
  z-index: 999;
  background-color: #fff;
  transition: height 0.25s;
}
.letter_pagesA {
  padding: 10px 17px;
  height: calc(100vh - 380px);
}
.letter_pagesB {
  padding: 0px;
  height: 0px;
}
.letter_box_css {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.letter_box_css:nth-child(1) .letter_css {
  width: 80px;
  height: 38px;
  font-size: 20px;
}

.letter_css {
  width: 46px;
  height: 46px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.chinese_box_css {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bgColor {
  background-color: #eaf5fe;
  color: #45adff;
}

.bgColor_hover {
  background: linear-gradient(to right, #4fcdfe, #38a8fe) !important;
  color: #fff !important;
}
.xe629_Css {
  position: absolute;
  top: 25px;
  right: 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
