<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="dia_title" v-if="studentIds.length > 1">
        点评{{ studentIds.length }}人
      </div>
      <div v-else class="flex-css-box">
        <div class="dia_title">
          点评 {{ studentIds[0] ? studentIds[0].userName : '' }}
        </div>
        <el-button type="primary" @click="studentFile(studentIds[0].userid)"
          >评价报告</el-button
        >
      </div>

      <div class="commentList_box_css">
        <div
          class="commentList_item_css"
          v-for="(item, index) in commentList"
          :key="index"
          :class="inforIndex == index ? 'commentList_item_css' + index : ''"
          @click="subsectionChange(index)"
        >
          {{ item.Title }}
        </div>
      </div>
      <div style="max-height: 400px; overflow-y: auto">
        <div class="recommend_item_css">
          <div
            v-for="(item, index) in commentList[inforIndex].recommendList"
            :key="index"
          >
            <div
              v-if="item.issys == 1"
              class="recommend_item_div_css"
              :class="{ select_bg_css: item.show }"
              @click="clickShow(index)"
            >
              {{ item.Title }}
            </div>
          </div>
        </div>
        <div class="recommend_item_css my_rate_cscs">
          <div
            v-for="(item, index) in commentList[inforIndex].recommendList"
            :key="index"
          >
            <div
              v-if="item.issys == 0"
              class="recommend_item_div_css"
              :class="{ select_bg_css: item.show }"
              @click="clickShow(index)"
            >
              {{ item.Title }}
            </div>
          </div>
        </div>
      </div>
      <div class="Explain_css">
        {{ Explain }}
      </div>
      <div class="rate_css">
        <el-rate v-model="Score"></el-rate>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closes()">关 闭</el-button>
        <el-button :disabled="disabled" type="primary" @click="scoreConfirm()"
          >确认点评</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getEvaluation, EvaluationSave } from '@/api/index.js'
export default {
  data() {
    return {
      dialogVisible: false, // 点评弹窗
      disabled: false, //是否禁用提交按钮
      studentIds: [],
      commentList: [
        { Title: '德', ttID: 1, recommendList: [] },
        { Title: '智', ttID: 2, recommendList: [] },
        { Title: '体', ttID: 3, recommendList: [] },
        { Title: '美', ttID: 4, recommendList: [] },
        { Title: '劳', ttID: 5, recommendList: [] },
      ],
      Explain: '',
      recommendList: [],
      Score: 0,
      inforIndex: 0,
      groupID: '1',
    }
  },
  created() {
    this.disabled = false
  },
  mounted() {
    this.$bus.$off('GetEvaluation')
    this.$bus.$on('GetEvaluation', () => {
      this.GetEvaluation(0)
    })

    this.$bus.$off('scorePopupTrue')
    // 打开打分弹窗
    this.$bus.$on('scorePopupTrue', (e) => {
      this.studentIds = e.ids
      this.groupID = e.groupID
      this.dialogVisible = true
    })
  },
  methods: {
    // 跳转至评价报告
    studentFile(id) {
      let routeData = this.$router.resolve({
        path: '/studentFile',
        query: {
          StudentID: id,
        },
      })
      // 打开新页面
      window.open(routeData.href, '_blank')
    },
    VoiceAnnouncements(text) {
      let utterThis = new SpeechSynthesisUtterance()
      utterThis.text = text
      utterThis.lang = 'zh' //汉语
      utterThis.rate = 0.7 //语速
      utterThis.pitch = 0.8 //音调
      speechSynthesis.speak(utterThis)
      return
      if (tape != null) {
        if (!tape.paused) {
          tape.pause()
        }
      }
      let url = 'http://dict.youdao.com/dictvoice?type=1&audio=youdao'
      tape = new Audio(url)
      tape.addEventListener('canplaythrough', (event) => {
        tape.play()
      })
    },
    // 获取点评项
    GetEvaluation(e, idx) {
      let _this = this
      getEvaluation({
        pID: e,
        classID: localStorage.getItem('classID') || '',
      }).then((res) => {
        if (e == 0) {
          _this.inforIndex = 0
          if (res.data.length !== 0) {
            _this.commentList = res.data
            _this.commentList.forEach((val, idx) => {
              val.recommendList = []
              _this.GetEvaluation(val.ttID, idx)
            })
          } else {
            _this.commentList = [
              { Title: '德', ttID: 1, recommendList: [] },
              { Title: '智', ttID: 2, recommendList: [] },
              { Title: '体', ttID: 3, recommendList: [] },
              { Title: '美', ttID: 4, recommendList: [] },
              { Title: '劳', ttID: 5, recommendList: [] },
            ]
            _this.commentList.map((val, idx) => {
              _this.GetEvaluation(val.ttID, idx)
            })
          }
        } else {
          res.data.forEach((val) => {
            val.show = false
          })
          _this.commentList[idx].recommendList = JSON.parse(
            JSON.stringify(res.data)
          )
        }
      })
    },
    // 点评分段器切换
    subsectionChange(e) {
      this.inforIndex = e
      this.$forceUpdate()
    },
    // 关闭弹窗
    closes() {
      this.dialogVisible = false
      this.inforIndex = 0
      this.studentIds = []
      this.Score = 0
      this.groupID = '1'
      this.commentList.forEach((val) => {
        val.recommendList.forEach((value) => {
          value.show = false
        })
      })
    },
    clickShow(index) {
      let blev = this.commentList[this.inforIndex].recommendList[index]
      this.Explain = blev.Title + ':' + blev.Explain
      this.commentList[this.inforIndex].recommendList[index].show = !this
        .commentList[this.inforIndex].recommendList[index].show
      this.$forceUpdate()
    },
    // 确定点评
    scoreConfirm() {
      if (this.studentIds.length == 0) {
        this.$message.error('请稍后再试')
        return
      }
      if (this.Score < 1) {
        this.$message.error('请选择分数')
        return
      }
      let arrList = []
      this.commentList.map((val) => {
        val.recommendList.map((value) => {
          if (value.show) {
            arrList.push({
              ttID: value.ttID,
              ttName: value.Title,
              score: this.Score,
            })
          }
        })
      })
      if (arrList.length == 0) {
        this.$message.error('请选择点评项')
        return
      }
      if (arrList.length > 10) {
        this.$message.error('最多只可点评 10 项')
        return
      }
      this.disabled = true
      EvaluationSave({
        ttID: JSON.stringify(arrList),
        EvaluationExplanation: '',
        EvalImages: '',
        sendSource: this.groupID == '1' ? 'PC端' : this.groupID,
        UserID: JSON.stringify(this.studentIds),
      })
        .then((res) => {
          if (this.studentIds.length == 1) {
            let num = Number(this.Score) * arrList.length
            this.VoiceAnnouncements(`${this.studentIds[0].userName}加${num}分`)
          }
          this.$parent.scoreShowFalse(this.studentIds)
          this.closes()
          this.disabled = false
          this.$message.success('点评成功')
        })
        .catch(() => {
          this.disabled = false
        })
    },
  },
}
</script>
<style scoped>
/deep/ .el-rate__icon {
  font-size: 48px !important;
}
/deep/ .el-icon-star-on {
  font-size: 53px !important;
}
/deep/ .el-rate {
  height: 38px !important;
}
.color3 {
  color: #db7739;
}
.color2 {
  color: #e3af49;
}
.color1 {
  color: #939abd;
}
.colorBox {
  font-size: 26px;
  margin-left: 15px;
}
.commentList_box_css {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.commentList_item_css {
  padding: 5px 35px;
  border-radius: 1000px;
  background-color: #e6e6e6;
  margin-right: 15px;
  font-size: 22px;
  /* font-weight: 600; */
  cursor: pointer;
  /* margin-left: -3px; */
}
.commentList_item_css0 {
  background-color: #5b7ad7 !important;
  color: #fff !important;
}
.commentList_item_css1 {
  background-color: #91cc75 !important;
  color: #fff !important;
}
.commentList_item_css2 {
  background-color: #fac858 !important;
  color: #fff !important;
}
.commentList_item_css3 {
  background-color: #f76b6b !important;
  color: #fff !important;
}
.commentList_item_css4 {
  background-color: #73c0de !important;
  color: #fff !important;
}
/* .commentList_item_css:nth-child(1){
      border-top: #8af862;
			border-right: #8af862;
			color: #8af862;
			border-bottom: #e1e1e1;
			border-left: transparent;
			border-width: 3px;
			border-style: solid;
}
.commentList_item_css:nth-child(2){
      border-top: #ff80c8;
			border-right: #ff80c8;
			color: #ff80c8;
			border-bottom: #e1e1e1;
			border-left: transparent;
			border-width: 3px;
			border-style: solid;
}
.commentList_item_css:nth-child(3){
      border-top: #ff46f6;
			border-right: #ff46f6;
			color: #ff46f6;
			border-bottom: #e1e1e1;
			border-left: transparent;
			border-width: 3px;
			border-style: solid;
}
.commentList_item_css:nth-child(4){
      border-top: #e2e24a;
			border-right: #e2e24a;
			color: #e2e24a;
			border-bottom: #e1e1e1;
			border-left: transparent;
			border-width: 3px;
			border-style: solid;
}
.commentList_item_css:nth-child(5){
      border-top: #747474;
			border-right: #747474;
			color: #747474;
			border-bottom: #e1e1e1;
			border-left: transparent;
			border-width: 3px;
			border-style: solid;
} */
.recommend_item_css {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.my_rate_cscs {
  border-top: 1px #e1e1e1 dashed;
  margin-top: 0px !important;
  padding-top: 10px;
}
.recommend_item_div_css {
  flex-shrink: 0;
  font-size: 20px;
  padding: 3px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #383838;
  border-radius: 20px;
  border: #dfdfdf 1px solid;
}
/deep/ .el-dialog__header {
  display: none;
}
.dia_title {
  font-size: 30px;
  color: #000;
}
.rate_css {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  margin-bottom: -20px;
}
.Explain_css {
  font-size: 18px;
  color: #9a9a9a;
  text-align: center;
  margin-bottom: -5px;
}
.select_bg_css {
  background-color: #55a1ec;
  border: #55a1ec 1px solid !important;
  color: #fff !important;
}
.flex-css-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
